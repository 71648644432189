import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '../Rebass';
import theme from '../../theme';
import Text from '../Text';
import Button from '../Button';
const NavButton = styled(Flex) `
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.baseGrayVeryLight};
  width: 30px;
  height: 30px;
  border-radius: 4px;
  transform: rotate(135deg);
  cursor: pointer;

  & .icon {
    font-size: 10px;
    transform: rotate(-135deg);
  }
`;
const PageNumber = styled(Flex)(({ isCurrentPage }) => `
  border-radius: 50%;
  background-color: ${isCurrentPage ? theme.colors.brandBrown : theme.colors.transparent};
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity ${theme.speed.default} ease;
  will-change: background-color, opacity;

  & span {
    transition: color 0.3s ease;
  }

  opacity: ${isCurrentPage ? theme.opacity.full : theme.opacity.medium};

  &:hover {
    opacity: ${theme.opacity.full};
    background-color: ${theme.colors.baseGrayVeryLight};
  }
`);
const Pagination = ({ currentPage, numberOfPages, goToPage, padding = 3, LinkComponent, to, }) => {
    const pageNumbers = Array(numberOfPages)
        .fill(0)
        .map((_, idx) => idx + 1);
    let sliced;
    if (currentPage <= padding + 1) {
        sliced = pageNumbers.slice(0, padding * 2 + 1);
    }
    else if (currentPage > numberOfPages - (padding + 1)) {
        sliced = pageNumbers.slice(numberOfPages - (padding * 2 + 1));
    }
    else {
        sliced = pageNumbers.slice(currentPage - (padding + 1), currentPage + padding);
    }
    if (numberOfPages <= 1) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { pl: [1], sx: { display: ['none', 'none', 'flex', 'flex'], alignItems: 'center' } },
            !sliced.includes(1) && (React.createElement(React.Fragment, null,
                React.createElement(Button, { as: LinkComponent, variant: "icon", size: "small", colorScheme: "transparent", iconColor: "textDark", icon: "chevron-double-left", onClick: !LinkComponent || !to ? () => goToPage(1) : undefined, to: !!LinkComponent && !!to ? `${to}` : undefined }),
                React.createElement(Button, { as: LinkComponent, variant: "icon", size: "small", colorScheme: "transparent", iconColor: "textDark", icon: "chevron-left", "data-testid": "prev-button", onClick: !LinkComponent || !to
                        ? () => goToPage(currentPage - 1)
                        : undefined, to: !!LinkComponent && !!to
                        ? `${to}${currentPage > 2 ? `?page=${currentPage - 1}` : ''}`
                        : undefined }))),
            sliced.map((pageNumber) => {
                const isCurrentPage = pageNumber === currentPage;
                return (React.createElement(PageNumber, { key: pageNumber, as: LinkComponent, isCurrentPage: isCurrentPage, onClick: !LinkComponent || !to ? () => goToPage(pageNumber) : undefined, to: !!LinkComponent && !!to
                        ? `${to}${pageNumber > 1 ? `?page=${pageNumber}` : ''}`
                        : undefined, mr: [1] },
                    React.createElement(Text, { size: "small", color: isCurrentPage ? 'textWhite' : 'textDark', fontWeight: "bold" }, pageNumber)));
            }),
            !sliced.includes(numberOfPages) && (React.createElement(React.Fragment, null,
                React.createElement(Button, { as: LinkComponent, variant: "icon", size: "small", colorScheme: "transparent", iconColor: "textDark", icon: "chevron-right", "data-testid": "next-button", onClick: !LinkComponent || !to
                        ? () => goToPage(currentPage + 1)
                        : undefined, to: !!LinkComponent && `${to}?page=${currentPage + 1}`
                        ? to
                        : undefined }),
                React.createElement(Button, { as: LinkComponent, variant: "icon", size: "small", colorScheme: "transparent", iconColor: "textDark", icon: "chevron-double-right", onClick: !LinkComponent || !to
                        ? () => goToPage(numberOfPages)
                        : undefined, to: !!LinkComponent && !!to
                        ? `${to}?page=${numberOfPages}`
                        : undefined })))),
        React.createElement(Box, { sx: {
                display: ['flex', 'flex', 'none', 'none'],
                justifyContent: 'space-between',
            } },
            React.createElement(Flex, { as: LinkComponent, sx: {
                    opacity: currentPage > 1 ? theme.opacity.full : theme.opacity.feint,
                    pointerEvents: currentPage > 1 ? 'auto' : 'none',
                    cursor: 'pointer',
                }, alignItems: "center", onClick: !!LinkComponent && !!to && currentPage > 1
                    ? () => goToPage(currentPage - 1)
                    : undefined, to: !!LinkComponent && !!to && currentPage > 1
                    ? `${to}${currentPage > 2 ? `?page=${currentPage - 1}` : ''}`
                    : undefined },
                React.createElement(Box, { mr: [1] },
                    React.createElement(Button, { as: LinkComponent, variant: "icon", icon: "chevron-left", colorScheme: "bgLight" })),
                React.createElement(Text, { size: "body", color: "textDark" }, "Previous")),
            React.createElement(Flex, { as: LinkComponent, sx: {
                    opacity: currentPage < numberOfPages
                        ? theme.opacity.full
                        : theme.opacity.feint,
                    pointerEvents: currentPage < numberOfPages ? 'auto' : 'none',
                    cursor: 'pointer',
                }, alignItems: "center", onClick: !!LinkComponent && !!to && currentPage < numberOfPages
                    ? () => goToPage(currentPage + 1)
                    : undefined, to: !!LinkComponent && !!to && currentPage < numberOfPages
                    ? `${to}?page=${currentPage + 1}`
                    : undefined },
                React.createElement(Text, { size: "body", color: "textDark" }, "Next"),
                React.createElement(Box, { ml: [1] },
                    React.createElement(Button, { as: LinkComponent, variant: "icon", icon: "chevron-right", colorScheme: "bgLight" }))))));
};
export default Pagination;
